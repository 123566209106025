import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Customer,
  ServiceChannel,
  Location,
  Route,
  TimeSlot,
  Product,
  Category,
  LineItem,
  DraftCustomOrder,
} from '#types';

import useCustomers from '#hooks/useCustomers';
import useServices from '#hooks/useServices';
import useRoutes from '#hooks/useRoutes';
import useScheduling from '#hooks/useTimeSlots';
import useProducts from '#hooks/useProducts';
import useCategories from '#hooks/useCategories';
import useOrders from '#hooks/useOrders';
import useOptions from '#hooks/useOptions';
import userSubscriptions from '#hooks/useSubscriptions';
import useNotes from '#hooks/useNotes';

import Segment from '#materials/Segment';
import Text from '#materials/Text';
import Button from '#materials/Button';

import Section from '#components/dashboard/Section';
import OrdersSearchControl from '#components/orders/OrdersSearch';
import ItemSearch from '#components/orders/ItemSearch';
import ItemList from '#components/lineItems/ItemList';
import PackingList from '#components/orders/PackingList';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.ordersReport;
const localeButtonKeys = locale.keys.buttons;

function keyOrder(order : DraftCustomOrder) {
  return order.order?.id
    ?? `${order.customer?.id}` +
      `-${order.serviceChannel?.id}` +
      `-${order.address?.id ?? order.location?.id}` +
      `-${order.timeSlot?.id}` +
      `-${order.timeSlotIteration}` +
      `-${order.timeSlotDivision}`;
}

interface OrdersReportProps {
  customerId? : number | null;
  serviceChannelId? : number | null;
  locationId? : number | null;
  routeId? : number | false | null;
  timeSlotId? : number | null;
  fromDate? : Date | null;
  toDate? : Date | null;
  productId? : number | null;
  categoryId? : number | null;
  setCustomer? : (customerId : number | null) => void;
  setServiceChannel? : (serviceChannelId : number | null) => void;
  setLocation? : (locationId : number | null) => void;
  setRoute? : (routeId : number | false | null) => void;
  setTimeSlot? : (timeSlotId : number | null) => void;
  setFromDate? : (date : Date | null) => void;
  setToDate? : (date : Date | null) => void;
  setProduct? : (productId : number | null) => void;
  setCategory? : (categoryId : number | null) => void;
  clear? : () => void;
}

function OrdersReport({
  customerId,
  serviceChannelId,
  locationId,
  routeId,
  timeSlotId,
  fromDate,
  toDate,
  productId,
  categoryId,
  setCustomer : setCustomerId,
  setServiceChannel : setServiceChannelId,
  setLocation : setLocationId,
  setRoute : setRouteId,
  setTimeSlot : setTimeSlotId,
  setFromDate,
  setToDate,
  setProduct : setProductId,
  setCategory : setCategoryId,
  clear,
} : OrdersReportProps) {
  const { customers } = useCustomers();
  const { serviceChannels, locations } = useServices();
  const { routes } = useRoutes();
  const { timeSlots } = useScheduling();
  const { products } = useProducts();
  const { categories } = useCategories();
  const { refreshLineItems, refreshOrders } = useOrders();
  const { refreshSelections } = useOptions();
  const { refreshSubscriptions } = userSubscriptions();
  const { refreshNotes } = useNotes();

  const [refreshing, setRefreshing] = useState(false);
  const [refreshed, setRefreshed] = useState(false);
  const [orders, setOrders] = useState<DraftCustomOrder[]>([]);
  const [productOrders, setProductOrders] = useState<DraftCustomOrder[]>([]);

  const [filteredOrders, setFilteredOrders] = useState<DraftCustomOrder[]>([]);
  const [filteredItems, setFilteredItems] = useState<LineItem[]>([]);

  const customer = useMemo(() => (
    customerId ? (customers?.[customerId] ?? null) : null
  ), [customerId, customers]);
  const serviceChannel = useMemo(() => (
    serviceChannelId ? (serviceChannels?.[serviceChannelId] ?? null) : null
  ), [serviceChannelId, serviceChannels]);
  const location = useMemo(() => (
    locationId ? (locations?.[locationId] ?? null) : null
  ), [locationId, locations]);
  const route = useMemo(() => (
    (routeId === false)
      ? false
      : (routeId ? (routes?.[routeId] ?? null) : null)
  ), [routeId, routes]);
  const timeSlot = useMemo(() => (
    timeSlotId ? (timeSlots?.[timeSlotId] ?? null) : null
  ), [timeSlotId, timeSlots]);
  const product = useMemo(() => (
    productId ? (products?.[productId] ?? null) : null
  ), [productId, products]);
  const category = useMemo(() => (
    categoryId ? (categories?.[categoryId] ?? null) : null
  ), [categoryId, categories]);

  const refresh = useCallback(async () => {
    if (refreshed || refreshing) return;
    setRefreshing(true);
    await Promise.all([
      refreshLineItems(),
      refreshOrders(),
      refreshSelections(),
      refreshSubscriptions(),
      refreshNotes(),
    ]);
    setRefreshing(false);
    setRefreshed(true);
  }, [
    refreshed,
    refreshing,
    refreshLineItems,
    refreshOrders,
    refreshSelections,
    refreshSubscriptions,
    refreshNotes,
  ]);

  const setCustomer = useCallback((c : Customer | null) => {
    setCustomerId?.(c?.id ?? null);
  }, [setCustomerId]);
  const setServiceChannel = useCallback((sc : ServiceChannel | null) => {
    setServiceChannelId?.(sc?.id ?? null);
  }, [setServiceChannelId]);
  const setLocation = useCallback((l : Location | null) => {
    setLocationId?.(l?.id ?? null);
  }, [setLocationId]);
  const setRoute = useCallback((r : Route | false | null) => {
    setRouteId?.((r === false) ? false : (r?.id ?? null));
  }, [setRouteId]);
  const setTimeSlot = useCallback((ts : TimeSlot | null) => {
    setTimeSlotId?.(ts?.id ?? null);
  }, [setTimeSlotId]);
  const setProduct = useCallback((p : Product | null) => {
    setProductId?.(p?.id ?? null);
  }, [setProductId]);
  const setCategory = useCallback((c : Category | null) => {
    setCategoryId?.(c?.id ?? null);
  }, [setCategoryId]);

  useEffect(() => {
    const uniqueOrders = orders.filter((order, index, self) => (
      self.findIndex((o) => keyOrder(o) === keyOrder(order)) === index
    ));
    if (!product && !category) setFilteredOrders(uniqueOrders);
    else setFilteredOrders(uniqueOrders.filter((order) => (
      order.complete && productOrders.includes(order)
    )));
  }, [product, category, orders, productOrders]);

  useEffect(() => { refresh(); }, [refresh]);

  const searching = !!customer
    || !!serviceChannel
    || !!location
    || !!route
    || !!fromDate
    || !!toDate;

  return (
    <>
      <Section title={localize(localeContentKeys.title)}>
        <OrdersSearchControl
          customer={customer}
          serviceChannel={serviceChannel}
          location={location}
          route={route}
          timeSlot={timeSlot}
          fromDate={fromDate}
          toDate={toDate}
          setCustomer={setCustomer}
          setServiceChannel={setServiceChannel}
          setLocation={setLocation}
          setRoute={setRoute}
          setTimeSlot={setTimeSlot}
          setFromDate={setFromDate}
          setToDate={setToDate}
          setOrders={setOrders}
          requireTimeSlot
          disabled={!refreshed || refreshing}
        />
        <Segment />
        <ItemSearch
          orders={orders}
          product={product}
          category={category}
          setProduct={setProduct}
          setCategory={setCategory}
          setLineItems={setFilteredItems}
          setOrders={setProductOrders}
          disabled={!refreshed || refreshing}
        />
        <Button
          onClick={clear}
          disabled={!refreshed || refreshing}
        >
          {localize(localeButtonKeys.clear)}
        </Button>
      </Section>
      { searching && (
        <Section title={localize(localeContentKeys.itemCount)}>
          { filteredItems.length
            ? (
              <ItemList
                lineItems={filteredItems}
                orders={filteredOrders}
                showFulfilmment
                mergeProducts
                mergePricing
                showProducts={category?.productIds ?? product}
              />
            ) : (
              <Text>
                { localize(localeContentKeys.noItems) }
              </Text>
            )
          }
        </Section>
      ) }
      { searching && filteredOrders.map((order) => (
        <PackingList
          key={keyOrder(order)}
          order={order}
          showProducts={category?.productIds ?? product}
        />
      )) }
    </>
  );
}

export default OrdersReport;
