import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Currency, Product, LineItem, DraftCustomOrder } from '#types';

import useProducts from '#hooks/useProducts';
import useOptions from '#hooks/useOptions';

import Table from '#materials/Table';
import { CellElement, TableCell } from '#materials/TableCell';

import { formatCurrency } from '#utils/currency';
import locale, { localize } from '#utils/locale';

const localeTableKeys = locale.keys.tables.lineItems;

const TABLE_KEYS = {
  id : 'id',
  sku : 'sku',
  product : 'product',
  quantity : 'quantity',
  unit : 'unit',
  price : 'price',
  actions : 'actions',
} as const;
export type TableKey = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
export const defaultTableKeys = Object.values(TABLE_KEYS);

interface ItemListProps {
  order? : DraftCustomOrder;
  lineItems? : LineItem[];
  orders? : DraftCustomOrder[];
  tableKeys? : TableKey[];
  generateActions? : (lineItem : LineItem) => CellElement;
  showFulfilmment? : boolean;
  mergeProducts? : boolean;
  mergePricing? : boolean;
  showProducts? : (Product | number)[] | Product | number | null;
}
interface OrderItemListProps extends ItemListProps {
  order : DraftCustomOrder;
  orders? : undefined;
}
interface OrdersItemListPros extends ItemListProps {
  order? : undefined;
  orders : DraftCustomOrder[];
}

function ItemList(props : OrderItemListProps) : JSX.Element;
function ItemList(props : OrdersItemListPros) : JSX.Element;
function ItemList({
  order,
  lineItems,
  orders,
  tableKeys = defaultTableKeys,
  showFulfilmment = true,
  mergeProducts = false,
  mergePricing = false,
  showProducts = [],
} : ItemListProps) {
  const merging = mergeProducts || mergePricing;

  const { products } = useProducts();
  const {
    getLineItemFulfilment,
    calculateSelectionPrice,
    resolveSelections,
  } = useOptions();

  const [head, setHead] = useState<React.ReactNode>([]);
  const [rows, setRows] = useState<React.ReactNode[]>([]);

  const applicableKeys = useMemo(() => tableKeys.filter((key) => (
    (!merging || (key !== TABLE_KEYS.id))
      && (!mergePricing || (key !== TABLE_KEYS.price))
  )), [tableKeys, merging, mergePricing]);

  const filterItems = useMemo(() => Array.isArray(showProducts)
    ? !!showProducts.length
    : !!showProducts, [showProducts]);
  const showProductIds = useMemo(
    () => Array.isArray(showProducts)
      ? showProducts.map((p) => (typeof p === 'number' ? p : p.id))
      : [typeof showProducts === 'number' ? showProducts : showProducts?.id],
    [showProducts],
  );

  const generateHead = useCallback(() => {
    setHead(
      <>
        { applicableKeys.map((key) => {
          switch(key) {
            case TABLE_KEYS.id:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.id) }
              </TableCell>);
            case TABLE_KEYS.sku:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.productSku) }
              </TableCell>);
            case TABLE_KEYS.product:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.product) }
              </TableCell>);
            case TABLE_KEYS.quantity:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.quantity) }
              </TableCell>);
            case TABLE_KEYS.price:
              return (<TableCell key={key}>
                { localize(localeTableKeys.headings.price) }
              </TableCell>);
            default: return (<TableCell key={key} />);
          }
        }) }
      </>
    );
  }, [applicableKeys]);

  const generateRows = useCallback(() => {
    const items : {
      id? : string;
      productId : number;
      price : Currency | null;
      quantity : number;
      show : boolean;
      highlight : boolean;
    }[] = [];

    const targetOrders = orders ?? [order];
    for (const targetOrder of targetOrders) {
      const orderLineItems = targetOrder
        ? Object.values(targetOrder.lineItems)
        : null;
      const targetLineItems = orderLineItems
        ? (lineItems?.length
          ? orderLineItems.filter((li) => lineItems.some((i) => i.id === li.id))
          : orderLineItems)
        : lineItems ?? [];
      for (const lineItem of targetLineItems) {
        const fulfilment = targetOrder
          ? getLineItemFulfilment(lineItem, targetOrder)
          : null;
        const productId = (showFulfilmment
          ? (fulfilment?.fulfilledProductId ?? fulfilment?.requestedProductId)
          : null) ?? lineItem.productId;
        const quantity = (showFulfilmment
          ? (fulfilment?.fulfilledQty ?? fulfilment?.requestedQty)
          : null) ?? lineItem.quantity;
        const price = (showFulfilmment ? fulfilment?.unitPrice : null)
          ?? lineItem.price;

        const existing = merging
          ? items.find((item) => ((item.productId === productId)
            && (mergePricing || (item.price?.amount === price?.amount))))
          : undefined;

        if (existing) {
          existing.quantity += quantity;
        } else {
          items.push({
            id : !merging
              ? lineItem.id + (fulfilment?.id ? `-${fulfilment?.id}` : '')
              : '',
            productId,
            price : lineItem.price ? { ...lineItem.price } : null,
            quantity,
            show : !filterItems || showProductIds.includes(productId),
            highlight : !filterItems || showProductIds.includes(productId),
          });
        }

        if (!targetOrder) continue;

        const resolvedSelections = resolveSelections(lineItem, targetOrder);
        if (!resolvedSelections) continue;
        for (const resolution of resolvedSelections) {
          const productId = (showFulfilmment
            ? (resolution.fulfilment?.fulfilledProductId
              ?? resolution.fulfilment?.requestedProductId)
            : null) ?? resolution.selection?.productId;
          const qty = (showFulfilmment
            ? (resolution.fulfilment?.fulfilledQty
              ?? resolution.fulfilment?.requestedQty)
            : null) ?? resolution.selection?.quantity;
          const price = (showFulfilmment
            ? resolution.fulfilment?.unitPrice
            : null) ?? (resolution.selection
              ? calculateSelectionPrice(resolution.selection)
              : null);

          if (!productId || !qty) continue;

          if (filterItems) {
            const parentItem = items.find((item) =>
              item.productId === lineItem.productId);
            if (parentItem && showProductIds.includes(productId)) {
              parentItem.show = true;
            }
          }

          const existing = merging
            ? items.find((item) => ((item.productId === productId)
              && (mergePricing || (item.price?.amount === price?.amount))))
            : undefined;

          if (existing) {
            existing.quantity += qty;
            continue;
          }

          items.push({
            id : !merging
              ? lineItem.id
                + (resolution.selection?.id
                  ? `-${resolution.selection.id}`
                  : '')
                + (fulfilment?.id ? `-${fulfilment?.id}` : '')
              : '',
            productId,
            price : resolution.fulfilment?.unitPrice ?? null,
            quantity : qty,
            show : !filterItems
              || showProductIds.includes(productId)
              || showProductIds.includes(lineItem.productId),
            highlight : !filterItems || showProductIds.includes(productId),
          });
        }
      }
    }

    setRows(items.filter((i) => i.show).map((item, i) => {
      const product = products?.[item.productId];
      const fade = filterItems && !item.highlight;
      return (
        <React.Fragment key={`${item.productId}-${i}`}>
          { applicableKeys.map((key) => {
            switch(key) {
              case TABLE_KEYS.id:
                return (<TableCell key={key} faded={fade}>
                  { item.id }
                </TableCell>);
              case TABLE_KEYS.sku:
                return (<TableCell key={key} faded={fade}>
                  { product?.sku }
                </TableCell>);
              case TABLE_KEYS.product:
                return (<TableCell key={key} faded={fade}>
                  { product?.name
                    ?? localize(localeTableKeys.defaults.product) }
                </TableCell>);
              case TABLE_KEYS.quantity:
                return (<TableCell key={key} faded={fade}>
                  { item.quantity }
                </TableCell>);
              case TABLE_KEYS.price:
                return (<TableCell key={key} faded={fade}>
                  { item.price ? formatCurrency(item.price) : null }
                </TableCell>);
              default: return (<TableCell key={key} />);
            }
          } )}
        </React.Fragment>
      )
    }));
  }, [
    lineItems,
    order,
    orders,
    showFulfilmment,
    mergePricing,
    applicableKeys,
    filterItems,
    showProductIds,
    merging,
    products,
    getLineItemFulfilment,
    calculateSelectionPrice,
    resolveSelections,
  ]);

  useEffect(() => { generateHead() }, [generateHead]);
  useEffect(() => { generateRows() }, [generateRows]);

  return (
    <Table
      head={head}
      rows={rows}
    />
  );
}

export default ItemList;
